import makeStyles from '@mui/styles/makeStyles';
import colors from '../../styles/colors';

export const useStyles = makeStyles({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, auto))',
    justifyContent: 'space-between',
    padding: '4px',
    gap: '12px',
  },
  linkTitle: {
    fontWeight: 500,
    fontSize: '14px',
    color: colors.gray.l15,
  },
  comingSoonPill: {
    marginLeft: '8px',
  },
});
