import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import BaseTab from '@mui/material/Tab';
import BaseTabs from '@mui/material/Tabs';
import React from 'react';
import colors from '../../styles/colors';

interface TabPanelProps {
  id: string;
  value?: string;
  children: JSX.Element;
}
export const TabPanel = ({ id, value, children }: TabPanelProps) => (
  <div
    role="tabpanel"
    hidden={value !== id}
    id={`tabpanel-${id}`}
    aria-labelledby={`tab-${id}`}
  >
    {value === id && <Box>{children}</Box>}
  </div>
);

interface TabsProps {
  value: string;
  onChange: (event, newValue) => void;
  variant: 'scrollable' | 'standard' | 'fullWidth';
  scrollButtons: boolean | 'auto';
  'aria-label'?: string;
  children: React.ReactNode;
}
export const Tabs = withStyles({
  root: {
    borderBottom: `1px solid ${colors.gray.l15}`,
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      width: '100%',
      backgroundColor: colors.gray.l15,
      zIndex: 1,
    },
  },
  scrollButtons: {
    '& > .MuiTouchRipple-root': {
      display: 'none',
    },
  },
})((props: TabsProps) => (
  <BaseTabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

interface TabProps {
  label: string;
  value: string;
}
export const Tab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      color: colors.gray.l15,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 500,
      marginRight: theme.spacing(1),
      '&:focus': {
        opacity: 1,
      },
      '&:hover': {
        opacity: 1,
      },
    },
  })
)((props: TabProps) => <BaseTab {...props} disableRipple />);
