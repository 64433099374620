import makeStyles from '@mui/styles/makeStyles';
import colors from '../../styles/colors';

export const useStyles = makeStyles({
  lineDivider: {
    height: '2px',
    width: '100%',
    background: colors.gray.l94,
    position: 'relative',
    left: '0',
    bottom: '2px',
  },
  tabsContainer: {
    marginTop: '24px',
  },
  tabPanelContainer: {
    marginTop: '24px',
  },
});
