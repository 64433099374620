import React from 'react';

function YoutubeIcon() {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.3008 2.64453C19.0898 1.80078 18.4219 1.13281 17.6133 0.921875C16.1016 0.5 10.125 0.5 10.125 0.5C10.125 0.5 4.11328 0.5 2.60156 0.921875C1.79297 1.13281 1.125 1.80078 0.914062 2.64453C0.492188 4.12109 0.492188 7.28516 0.492188 7.28516C0.492188 7.28516 0.492188 10.4141 0.914062 11.9258C1.125 12.7695 1.79297 13.4023 2.60156 13.6133C4.11328 14 10.125 14 10.125 14C10.125 14 16.1016 14 17.6133 13.6133C18.4219 13.4023 19.0898 12.7695 19.3008 11.9258C19.7227 10.4141 19.7227 7.28516 19.7227 7.28516C19.7227 7.28516 19.7227 4.12109 19.3008 2.64453ZM8.15625 10.1328V4.4375L13.1484 7.28516L8.15625 10.1328Z" />
    </svg>
  );
}

export default YoutubeIcon;
