import makeStyles from '@mui/styles/makeStyles';
import colors from '../../styles/colors';

export const useStyles = makeStyles({
  noStyleLink: {
    '&, &:hover, &:focus, &:active': {
      textDecoration: 'none',
      color: 'black',
      outline: 0,
      display: 'block',
    },
    '&:focus-visible': {
      boxShadow: `0px 0px 0px 2px ${colors.blue.l50}`,
    },
  },
});
