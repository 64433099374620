import { SeoListType } from '../../types/seo-list-type';

const marketSectors = {
  commercial: {
    name: 'Commercial',
    icon: '/construction-sector/commercial.png',
    urlKey: 'commercial',
    description:
      'Commercial construction is the business of building and leasing or selling spaces in the private sector. These spaces can include but are not limited to offices, manufacturing plants, medical centers, and retail shopping centers. Commercial construction projects and businesses primarily vary in size and scale. The size of a construction project can be dependent upon a number of factors such as the type of clientele that the commercial real estate property or industrial complex is designed to serve, the length of time it takes to complete the project, any legalities that need to be completed prior to occupancy, and much more.',
  },

  healthcare: {
    name: 'Healthcare',
    icon: '/construction-sector/healthcare.png',
    urlKey: 'healthcare',
    description:
      'The healthcare industry has evolved significantly in recent years, with more of a focus on services that keep patients healthy. Healthcare experts need to be on top of the latest healthcare trends. This allows their teams to design and build hospitals that not only meet the standards of today but will grow with the industry over the coming years. Businesses that specialize in healthcare make hospitals, physician offices, labs and more are designed to meet the needs of today and tomorrow.',
  },

  industrial_and_energy: {
    name: 'Industrial and Energy',
    icon: '/construction-sector/industrial-energy.png',
    urlKey: 'industrial-and-energy',
    description:
      'Industrial construction services expertise include but are not limited to: petrochemical, energy and natural gas, power plant construction, manufacturing plant construction, expansions and upgrades, structural steel installation, industrial piping systems, machinery and equipment procurement and installation, distributed energy generation construction, and mechanical construction.',
  },

  infrastructure: {
    name: 'Infrastructure',
    icon: '/construction-sector/infrastructure.png',
    urlKey: 'infrastructure',
    description:
      'Infrastructure construction companies build large buildings, bridges, dams, pipelines, road networks, ports, railways, and aqueducts. Most large infrastructure construction projects have a single central company that oversees the entire project. These projects will typically require hundreds or even thousands of subcontractors to provide different types of services.',
  },

  institutional: {
    name: 'Institutional',
    icon: '/construction-sector/institutional.png',
    urlKey: 'institutional',
    description:
      'Institutional construction is the construction of public buildings, such as schools, and recreational centers. These structures are subject to stricter regulations than residential buildings and require a unique skillset.',
  },

  residential: {
    name: 'Residential',
    icon: '/construction-sector/residential.png',
    urlKey: 'residential',
    description:
      'Residential construction includes the building and selling of multifamily as well as individual family dwellings. Residential dwellings can include single-units, manufactured housing, such as mobile homes and pre-built houses, duplexes, quadplexes, apartment buildings as well as condominiums.',
  },
};

export const iterableMarketSectors = Object.values(marketSectors);

export const findMarketSector = (input: string): [string, SeoListType] => {
  if (!input) {
    return undefined;
  }
  const newInput = input.toLowerCase().trim();
  return Object.entries(marketSectors).find(
    ([key, value]) =>
      newInput === value.name.toLowerCase() ||
      newInput === value.urlKey.toLowerCase() ||
      newInput === key
  );
};

export const isMarketSector = (input: string): boolean => {
  return !!findMarketSector(input);
};

export default marketSectors;
