import React from 'react';
import classnames from 'classnames';
import { useStyles } from './NoStyleLink.styles';

const NoStyleLink = ({
  href,
  className,
  children,
  ...otherProps
}: React.HTMLProps<HTMLAnchorElement>) => {
  const classes = useStyles();
  return (
    <a
      {...otherProps}
      href={href}
      className={classnames(classes.noStyleLink, className)}
    >
      {children}
    </a>
  );
};

export default NoStyleLink;
