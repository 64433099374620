import React from 'react';

function LinkedInIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.51562 16V5.48828H0.246094V16H3.51562ZM1.86328 4.08203C2.91797 4.08203 3.76172 3.20312 3.76172 2.14844C3.76172 1.12891 2.91797 0.285156 1.86328 0.285156C0.84375 0.285156 0 1.12891 0 2.14844C0 3.20312 0.84375 4.08203 1.86328 4.08203ZM15.7148 16H15.75V10.2344C15.75 7.42188 15.1172 5.24219 11.8125 5.24219C10.2305 5.24219 9.17578 6.12109 8.71875 6.92969H8.68359V5.48828H5.55469V16H8.82422V10.7969C8.82422 9.42578 9.07031 8.125 10.7578 8.125C12.4453 8.125 12.4805 9.67188 12.4805 10.9023V16H15.7148Z" />
    </svg>
  );
}

export default LinkedInIcon;
