import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import colors from '../../styles/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    width: '100%',
  },
  fixedFooter: {
    height: '88px',
  },
  topSection: {
    paddingTop: '30px',
    paddingBottom: '100px',
    backgroundColor: colors.white,
    '& > .MuiContainer-root': {
      [theme.breakpoints.down(1280)]: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
  },
  bottomSection: {
    backgroundColor: colors.gray.l96,
    color: colors.gray.l30,
    alignItems: 'center',
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'absolute',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '24px 16px',
    },
    '& a': {
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '12px',

      '&, &:hover, &:visited, &:active': {
        textDecoration: 'none',
        color: 'inherit',
      },
      '&:hover, &:focus': {
        color: colors.orange.l50,
      },
    },
  },
  textLinks: {
    '&  > *': {
      marginBottom: '20px',
      marginRight: '20px',
    },
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      marginTop: theme.spacing(3),
      alignItems: 'flex-start',
    },
  },
  copyright: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '12px',
  },
}));

export const useSocialStyles = makeStyles(() => ({
  social: {
    '& svg': {
      fill: colors.gray.l30,
    },
    '&:hover svg,': {
      fill: colors.orange.l50,
    },
    '&:focus-within svg': {
      fill: colors.orange.l50,
    },
  },
}));
