import { SeoListType } from '../../types/seo-list-type';

const businessTypes = {
  ARCHITECT: {
    name: 'Architects',
    icon: '/business-type/architects.png',
    urlKey: 'architects',
    description:
      'An architect is a professional who designs, plans, and supervises the construction of structures. Providing services in conjunction with the design of buildings and the space inside the site around buildings that have human occupancy or use as their primary purpose is what it means to practice architecture.',
  },
  ENGINEER: {
    name: 'Engineers',
    icon: '/business-type/engineers.png',
    urlKey: 'engineers',
    description:
      'A construction engineer is a civil engineer responsible for the oversight and management of a massive, complex construction projects. Typically, these large-scale projects include both the  buildings and the infrastructure that supports them. Construction engineers may collaborate with other engineers, and also manage building crews, to ensure the project is successful.',
  },
  GENERAL_CONTRACTOR: {
    name: 'General Contractors',
    icon: '/business-type/general-contractors.png',
    urlKey: 'general-contractors',
    description:
      'General Contractors are the parties in charge of overseeing a construction project and who signs the main or "prime" contract with the property owner.',
  },
  OWNER_REAL_ESTATE_DEVELOPER: {
    name: 'Owner / Real Estate Developers',
    icon: '/business-type/owners.png',
    urlKey: 'owner-real-estate-developers',
    description:
      'Real Estate Developers typically buy land, finance real estate deals, build or have builders build projects.  Developers take large risks in the creation or renovation of real estate. Typically, real estate developers purchase land, determine the marketing of the property, develop the building program and design,  oversee the building of the structures, and then rent out, manage, or ultimately sell it.',
  },
  SPECIALTY_CONTRACTOR: {
    name: 'Specialty Contractors',
    icon: '/business-type/specialty-contractors.png',
    urlKey: 'specialty-contractors',
    description:
      'Specialty Contractors usually have a Class C license, suggesting that they have a very specific set of skills and experiences that are only applicable to one niche. Because of their expertise, they are well-suited to certain construction and maintenance duties, and they are frequently engaged as subcontractors or contracted for specific renovation projects.',
  },
  SUPPLIER: {
    name: 'Suppliers and Vendors',
    icon: '/business-type/vendors.png',
    urlKey: 'suppliers-and-vendors',
    description:
      'A vendors and suppliers are classified as a person or company that other construction companies use to purchase goods or services. Suppliers typically do not provide any project labor on the jobsite. Vendors and suppliers provide any of the following: equipment, materials, parts, any services not performed on the project jobsite, etc.',
  },
  CONSULTANT: {
    name: 'Consultants',
    icon: '/business-type/consultants.png',
    urlKey: 'consultants',
    description:
      'Construction consultants are industry professionals that help their clients optimize construction projects by preparing cost estimates and contracts, analyzing blueprints, and working with vendors. They typically have a background in construction or construction management.',
  },
};

export const iterableBusinessTypes = Object.values(businessTypes);

export const findBusinessType = (input: string): [string, SeoListType] => {
  if (!input) {
    return undefined;
  }
  const newInput = input.toLowerCase().trim();
  return Object.entries(businessTypes).find(
    ([key, value]) =>
      newInput === value.name.toLowerCase() ||
      newInput === value.urlKey.toLowerCase() ||
      newInput.toUpperCase() === key
  );
};

export const isBusinessType = (input: string): boolean => {
  return !!findBusinessType(input);
};

export default businessTypes;
