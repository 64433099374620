import React from 'react';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { Typography } from '@mui/material';
import { useI18nContext, Pill } from '@procore/core-react';
import businessTypes from '../../data/filters/business-type';
import { DEFAULT_COUNTRY, CANADA } from '../../data/location/countries';
import { getStateList } from '../../data/location/states';
import marketSectors from '../../data/filters/market-sectors';
import { providedServices } from '../../data/filters/provided-services';
import AnalyticsWrappedComponent from '../Analytics/AnalyticsWrappedComponent';
import { useStyles } from './DiscoverLinks.styles';
import NoStyleLink from '../ButtonsLinks/NoStyleLink';

const { publicRuntimeConfig } = getConfig();

interface LinkOptionProps {
  title: string;
  href: string;
}
export const LinkOption = ({ title, href }: LinkOptionProps) => {
  const I18n = useI18nContext();
  const classes = useStyles();
  const analyticsWrappedComponentStyle: {
    textDecoration: string;
    cursor?: string;
    'pointer-events'?: string;
  } = {
    textDecoration: 'none',
  };
  if (title === 'Québec') {
    analyticsWrappedComponentStyle.cursor = 'default';
    analyticsWrappedComponentStyle['pointer-events'] = 'none';
  }
  return (
    <AnalyticsWrappedComponent
      componentType={NoStyleLink}
      eventText="Clicked Discover Link"
      eventLabel={title}
      href={href}
      style={analyticsWrappedComponentStyle}
    >
      <Typography component="h3" variant="h1" className={classes.linkTitle}>
        {title}
        {title === 'Québec' && (
          <Pill color="blue" className={classes.comingSoonPill}>
            {I18n.t('components.discover_component.coming_soon')}
          </Pill>
        )}
      </Typography>
    </AnalyticsWrappedComponent>
  );
};

interface DiscoverLinksProps {
  links: Array<LinkOptionProps>;
}
function DiscoverLinks({ links }: DiscoverLinksProps) {
  const classes = useStyles();
  return (
    <div className={classes.grid}>
      {links.map((link) => (
        <LinkOption {...link} key={link.href} />
      ))}
    </div>
  );
}

export const DiscoverBusinessTypes = () => (
  <DiscoverLinks
    links={Object.values(businessTypes).map((type) => ({
      title: type.name,
      href: `${publicRuntimeConfig.basePath}/business-types/${type.urlKey}`,
    }))}
  />
);

export const DiscoverMarketSectors = () => (
  <DiscoverLinks
    links={Object.values(marketSectors).map((type) => ({
      title: type.name,
      href: `${publicRuntimeConfig.basePath}/market-sectors/${type.urlKey}`,
    }))}
  />
);

export const DiscoverProvidedServices = () => (
  <DiscoverLinks
    links={Object.values(providedServices).map((type) => ({
      title: type.name,
      href: `${publicRuntimeConfig.basePath}/trades/${type.urlKey}`,
    }))}
  />
);

export const DiscoverLocations = () => {
  const { locale } = useRouter();
  const countryCode = locale.slice(-2).toLowerCase();
  const country =
    locale === 'en-CA' || locale === 'fr-CA' ? CANADA : DEFAULT_COUNTRY;
  const states = getStateList(country);

  return (
    <DiscoverLinks
      links={states
        .map((state) => ({
          title: state.name,
          href: `${
            publicRuntimeConfig.basePath
          }/${country.alpha2.toLowerCase()}/${state.abbreviation.toLowerCase()}`,
        }))
        .sort((a, b) => a.title.localeCompare(b.title))}
      data-test-id={`discover-links-${countryCode}`}
    />
  );
};

export default DiscoverLinks;
