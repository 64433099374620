import { Box, Typography } from '@mui/material';
import { useI18nContext } from '@procore/core-react';
import React from 'react';
import { useStyles } from './DiscoverComponent.styles';
import { Tab, TabPanel, Tabs } from '../Layout/Tabs';

interface DiscoverComponentProps {
  tabs: Array<{
    id: string;
    name: string;
    Component: ({ id, name }: { id: string; name: string }) => JSX.Element;
  }>;
}

export default function DiscoverComponent({ tabs }: DiscoverComponentProps) {
  const I18n = useI18nContext();
  const classes = useStyles();
  const [value, setValue] = React.useState(tabs[0]?.id);

  const handleChange = (_, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div>
      <Typography variant="h2">
        {I18n.t('components.discover_component.discover')}
      </Typography>
      <Box className={classes.tabsContainer}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="tabs"
          >
            {tabs.map(({ id, name }) => (
              <Tab label={name} key={id} value={id} />
            ))}
          </Tabs>
          <div className={classes.lineDivider} />
        </Box>
        <div className={classes.tabPanelContainer}>
          {tabs.map(({ id, name, Component }) => (
            <TabPanel id={id} value={value} key={id}>
              <Component id={id} name={name} />
            </TabPanel>
          ))}
        </div>
      </Box>
    </div>
  );
}
