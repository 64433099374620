import { Box, Container, Theme, useMediaQuery } from '@mui/material';
import { useI18nContext } from '@procore/core-react';
import classNames from 'classnames';
import getConfig from 'next/config';
import React from 'react';
import DiscoverComponent from '../Discover/DiscoverComponent';
import {
  DiscoverBusinessTypes,
  DiscoverLocations,
  DiscoverMarketSectors,
  DiscoverProvidedServices,
} from '../Discover/DiscoverLinks';
import { useSocialStyles, useStyles } from './Footer.styles';
import FacebookIcon from '../Icons/social/FacebookIcon';
import InstagramIcon from '../Icons/social/InstagramIcon';
import LinkedInIcon from '../Icons/social/LinkedInIcon';
import TwitterIcon from '../Icons/social/TwitterIcon';
import YoutubeIcon from '../Icons/social/YoutubeIcon';
import basePath from '../../helpers/basePath';

const { publicRuntimeConfig } = getConfig();

const SocialIcon: React.FC<{
  href: string;
  children?: React.ReactNode;
  label: string;
  mr?: number;
}> = ({ href, children, label, mr = 3 }) => {
  const classes = useSocialStyles();

  return (
    <Box
      className={classes.social}
      mr={mr}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <a
        aria-label={label}
        data-track-click={`Footer, Navigation, ${children}`}
        href={href}
        rel="nofollow"
      >
        {children}
      </a>
    </Box>
  );
};

const Footer = ({
  showDiscover = true,
  fixedHeight = false,
}: {
  showDiscover?: boolean;
  fixedHeight?: boolean;
}) => {
  const I18n = useI18nContext();
  const classes = useStyles();
  const mdView = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const tabs = [
    {
      id: 'company-types',
      name: I18n.t('components.footer.company_types'),
      Component: DiscoverBusinessTypes,
    },
    {
      id: 'market-sectors',
      name: I18n.t('components.footer.market_sectors'),
      Component: DiscoverMarketSectors,
    },
    {
      id: 'construction-trades',
      name: I18n.t('components.footer.construction_trades'),
      Component: DiscoverProvidedServices,
    },
    {
      id: 'service-areas',
      name: I18n.t('components.footer.service_areas'),
      Component: DiscoverLocations,
    },
  ];

  const textLinks = [
    <Box key="Network" mr={3}>
      <a
        data-test-id="network-link"
        data-track-click="Footer, Navigation, Network"
        href={basePath()}
      >
        {I18n.t('components.footer.network')}
      </a>
    </Box>,
    <Box key="Privacy Notice" mr={3}>
      <a
        data-test-id="privacy-notice"
        data-track-click="Footer, Navigation, Privacy Notice"
        href="https://www.procore.com/legal/privacy"
        rel="nofollow"
      >
        {I18n.t('components.footer.privacy')}
      </a>
    </Box>,
    <Box key="Terms of Service">
      <a
        data-test-id="terms-of-service"
        data-track-click="Footer, Navigation, Terms of Service"
        href="https://www.procore.com/legal/terms-of-service"
        rel="nofollow"
      >
        {I18n.t('components.footer.terms')}
      </a>
    </Box>,
    <Box key="Do Not Sell Personal Information">
      <a
        data-test-id="consumer-rights"
        data-track-click="Footer, Navigation, Do Not Sell Personal Information"
        href="https://www.procore.com/legal/california-consumer-rights"
        rel="nofollow"
      >
        {I18n.t('components.footer.sell')}
      </a>
    </Box>,
    <Box key="Sitemap">
      <a
        data-test-id="sitemap"
        data-track-click="Footer, Navigation, Sitemap"
        href={`${publicRuntimeConfig.basePath}/sitemap`}
        rel="nofollow"
      >
        {I18n.t('components.footer.sitemap')}
      </a>
    </Box>,
  ];

  const copyright = (
    <Box
      data-test-id="copyright"
      key="Copyright"
      mr={3}
      className={classes.copyright}
    >
      © {new Date().getFullYear()} Procore Technologies, Inc.
    </Box>
  );

  if (mdView) {
    textLinks.push(copyright);
  } else {
    textLinks.unshift(copyright);
  }

  const textLinksElement = (
    <Box className={classes.textLinks} display="flex" alignItems="center">
      {textLinks}
    </Box>
  );

  const socialIconsElement = (
    <Box display="flex">
      <SocialIcon
        label="Linked In"
        href="https://www.linkedin.com/company/procore-technologies"
      >
        <LinkedInIcon />
      </SocialIcon>
      <SocialIcon label="Facebook" href="https://www.facebook.com/procore.tech">
        <FacebookIcon />
      </SocialIcon>
      <SocialIcon label="Twitter" href="https://www.twitter.com/procoretech">
        <TwitterIcon />
      </SocialIcon>
      <SocialIcon
        label="Instagram"
        href="https://www.instagram.com/procoretech"
      >
        <InstagramIcon />
      </SocialIcon>
      <SocialIcon
        label="YouTube"
        href="https://www.youtube.com/user/ProcoreVideo"
        mr={0}
      >
        <YoutubeIcon />
      </SocialIcon>
    </Box>
  );
  return (
    <footer
      className={classNames(classes.footer, fixedHeight && classes.fixedFooter)}
      role="contentinfo"
    >
      {showDiscover && (
        <Box component="section" py={2} className={classes.topSection}>
          <Container aria-label="Discover professionals">
            <DiscoverComponent tabs={tabs} />
          </Container>
        </Box>
      )}
      <Box
        className={classes.bottomSection}
        component="section"
        display="flex"
        justifyContent="space-between"
        px={6}
        py={3}
        maxWidth="1680px"
        width="100%"
      >
        {mdView ? (
          <>
            {socialIconsElement}
            {textLinksElement}
          </>
        ) : (
          <>
            {textLinksElement}
            {socialIconsElement}
          </>
        )}
      </Box>
    </footer>
  );
};

export default Footer;
